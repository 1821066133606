// This method returns card category tags in the order of the keys provided by the dictionary
// We pick the key from dictionary and extract the tag from api card data
// Each page is having different keys and its order that comes from dictionary service dynamically generated using page contentType
export const getPriceRange = (expenseValues = []) => {
  let expenseRange = '';
  if (expenseValues && expenseValues.length > 0) {
    const expenseRangeParam = [
      [1, 200],
      [201, 500],
      [501],
    ];
    const expenseRangeSigns = [
      '$',
      '$$',
      '$$$',
    ];
    const eachHighestNumb = [];
    expenseValues.forEach(value => {
      const breakVal = value.split(/[\ \-]/);
      const numbers = [];

      breakVal.forEach(val => {
        let isNumVal = parseInt(val);
        if (isNumVal) {
          // check if val has a + sign
          if (val.indexOf('+') > -1) {
            isNumVal++;
          }
          numbers.push(isNumVal)
        }
      });
      eachHighestNumb.push(Math.max(...numbers));
    });
    const overAllHighest = Math.max(...eachHighestNumb);
    expenseRangeParam.forEach((range, index) => {
      if (!range[1]) {
        if (overAllHighest >= range[0]) {
          expenseRange = expenseRangeSigns[index];
        }
      } else {
        if (overAllHighest >= range[0] && overAllHighest <= range[1]) {
          expenseRange = expenseRangeSigns[index];
        }
      }
    });
  }
  return expenseRange;
};
