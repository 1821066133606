import { canUseDOM } from './canUseDOM';
import { getDictionaryText } from './getDictionaryText';

export const getRedirectionUrl = (item, language) => {
  const eventListingPageUrl = getDictionaryText('eventListingPageUrl');
  let redirectionUrl;
  if (canUseDOM && window && window.location) {
    redirectionUrl = `${
      window.location.origin
      }/${language.toLowerCase()}${eventListingPageUrl}/${item.urlSlug ? item.urlSlug : ''}`;
  } else {
    redirectionUrl = `/${language.toLowerCase()}${eventListingPageUrl}/${item.urlSlug ? item.urlSlug : ''}`;
  }
  return redirectionUrl;
};
