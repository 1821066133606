/* eslint-disable max-len */
import React from 'react';
import { palette } from '../themes/vad/palette';

export const BurgerIconBlack = (
  <svg
    width="22px"
    height="16px"
    viewBox="0 0 22 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <title>Combined Shape@1.5x</title>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="02_Components/01_Global/Nav/Mobile/Transparent"
        transform="translate(-324.000000, -34.000000)"
        fill="#000000"
        fillRule="nonzero"
      >
        <g id="Header" transform="translate(24.000000, 18.000000)">
          <g
            id="Misc-/-BurgerMenu-/-Static"
            transform="translate(295.000000, 8.000000)"
          >
            <g id="Icons-/-Functional-/-icon-burger-menu">
              <g>
                <g
                  transform="translate(16.000000, 16.000000) scale(-1, 1) translate(-16.000000, -16.000000) "
                >
                  <path d="M25.1559311,21.969498 C25.722291,21.969498 26.1805032,22.4236892 26.1805032,22.984749 C26.1805032,23.5458088 25.722291,24 25.1289615,24 L6.35817512,24 C5.79181519,24 5.33333333,23.5458088 5.33333333,22.984749 C5.33333333,22.4236892 5.79181519,21.969498 6.35817512,21.969498 L25.1559311,21.969498 Z M21.3093229,14.8722825 C21.7657797,14.8722825 22.1350751,15.3264738 22.1350751,15.8875335 C22.1350751,16.4485933 21.7657797,16.9027846 21.2875868,16.9027846 L6.15930286,16.9027846 C5.70284602,16.9027846 5.33333333,16.4485933 5.33333333,15.8875335 C5.33333333,15.3264738 5.70284602,14.8722825 6.15930286,14.8722825 L21.3093229,14.8722825 Z M25.1559311,8 C25.722291,8 26.1805032,8.45419125 26.1805032,9.01525102 C26.1805032,9.5763108 25.722291,10.030502 25.1289615,10.030502 L6.35817512,10.030502 C5.79181519,10.030502 5.33333333,9.5763108 5.33333333,9.01525102 C5.33333333,8.45419125 5.79181519,8 6.35817512,8 L25.1559311,8 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const BurgerIconWhite = (
  <svg
    width="22px"
    height="16px"
    viewBox="0 0 22 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <title>Combined Shape@1.5x</title>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="02_Components/01_Global/Nav/Mobile/Transparent"
        transform="translate(-324.000000, -34.000000)"
        fill={palette.common.White}
        fillRule="nonzero"
      >
        <g id="Header">
          <g
            id="Misc-/-BurgerMenu-/-Static"
            transform="translate(319.000000, 26.000000)"
          >
            <g id="Icons-/-Functional-/-icon-burger-menu">
              <g>
                <g
                  transform="translate(16.000000, 16.000000) scale(-1, 1) translate(-16.000000, -16.000000) "
                >
                  <path d="M25.1559311,21.969498 C25.722291,21.969498 26.1805032,22.4236892 26.1805032,22.984749 C26.1805032,23.5458088 25.722291,24 25.1289615,24 L6.35817512,24 C5.79181519,24 5.33333333,23.5458088 5.33333333,22.984749 C5.33333333,22.4236892 5.79181519,21.969498 6.35817512,21.969498 L25.1559311,21.969498 Z M21.3093229,14.8722825 C21.7657797,14.8722825 22.1350751,15.3264738 22.1350751,15.8875335 C22.1350751,16.4485933 21.7657797,16.9027846 21.2875868,16.9027846 L6.15930286,16.9027846 C5.70284602,16.9027846 5.33333333,16.4485933 5.33333333,15.8875335 C5.33333333,15.3264738 5.70284602,14.8722825 6.15930286,14.8722825 L21.3093229,14.8722825 Z M25.1559311,8 C25.722291,8 26.1805032,8.45419125 26.1805032,9.01525102 C26.1805032,9.5763108 25.722291,10.030502 25.1289615,10.030502 L6.35817512,10.030502 C5.79181519,10.030502 5.33333333,9.5763108 5.33333333,9.01525102 C5.33333333,8.45419125 5.79181519,8 6.35817512,8 L25.1559311,8 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
