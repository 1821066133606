import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../styles/themes/vad/constants';
import { fontSize } from '../../../../styles/themes/vad/font';

export default makeStyles(theme => ({
  skipToMain: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      position: 'fixed',
      top: '-150px',
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: '1500',
      background: `${theme.mixins.White()}`,
      transition: 'top .3s ease-in',
      '&:focus': {
        top: 0,
        transition: 'top .1s ease-in',
      },
      '&:focus-within': {
        top: 0,
        transition: 'top .1s ease-in',
      },
      '& div': {
        whiteSpace: 'nowrap',
        color: 'black',
        padding: '10px 60px',
        textDecoration: 'none',
      },
    },
  },
  root: {
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      ...theme.mixins.marginRight(0),
    },
    // Logo & Menu list width.
    '@media (min-width: 1024px)': {
      '& [data-locator="header-component-grid-item-logo"]': {
        maxWidth: 'none',
        flexBasis: 'auto',
      },
      '& .MuiGrid-grid-sm-10': {
        maxWidth: 'calc(100% - 10rem)',
        flexBasis: 'calc(100% - 10rem)',
      },
    },
    '@media (min-width: 1024px) and (max-width: 1140px)': {
      '& [data-locator="header-component-grid-item-logo"]': {
        ...theme.mixins.marginLeft('-2%'),
      },
    },
  },
  holdSpaceForFav: {
    marginBottom: 'calc(var(--favMobileBar) - 8px)',
  },
  headerContainer: {
    // paddingTop: theme.spacing(2),
    // paddingBottom: theme.spacing(2),
    minHeight: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      minHeight: theme.spacing(16),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    '& .logo-red': {
      display: 'none',
    },
  },
  headerContainerPlaceholder: {
    minHeight: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      minHeight: theme.spacing(16),
    },
  },
  headerTransparent: {
    background: 'none',
    color: theme.palette.primary.contrastText,
    transition: 'all 0ms 100ms',
    '& .search-icon': {
      '& svg': {
        '& path': {
          fill: theme.palette.primary.contrastText,
        },
      },
    },
    '& .mega-navigation-list a:not(.active) span:not(:hover)': {
      color: theme.palette.primary.contrastText,
    },
    '&.hidden': {
      transform: 'translateY(-14rem) !important',
      opacity: 0,
    },
    '& .language-selector-wrapper': {
      '& svg path': {
        fill: theme.mixins.White(),
      },
    },
    '&.megaNavOpened': {
      '& .logo-img': {
        display: 'none',
      },
      '& .logo-red': {
        display: 'block',
      },
      '& .search-icon': {
        '& svg': {
          '& path': {
            fill: theme.palette.primary.main,
          },
        },
      },
      '& .MuiList-root .nav-text': {
        [theme.breakpoints.up('lg')]: {
          '&::after': {
            borderColor: theme.mixins.AbuDhabiOrange(),
          },
          '&:hover': {
            color: theme.mixins.AbuDhabiOrange(),
          },
        },
      },
    },
    '& .MuiList-root .nav-text': {
      [theme.breakpoints.up('lg')]: {
        '&::after': {
          borderColor: theme.mixins.White(),
        },
        '&:hover': {
          color: theme.mixins.White(),
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      transition: 'all 0ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    },
  },
  visibleHeader: {
    zIndex: '9999',
  },
  iconDivider: {
    width: '100%',
    height: pxToRem(6),
    background: theme.mixins.White(1000),
    position: 'absolute',
    bottom: pxToRem(-5),
  },
  searchOpened: {
    [theme.breakpoints.down('md')]: {
      '&.MuiButtonBase-root': {
        borderRight: `1px solid ${theme.mixins.Black(100)}`,
        borderLeft: `1px solid ${theme.mixins.Black(100)}`,
      },
    },
  },
  appBarHeader: {
    background: theme.mixins.White(1000),
  },
  headerWrapper: {
    color: theme.palette.secondary.contrastText,
    background: theme.palette.secondary.main,
    boxShadow: `0px 0px 5px 0px ${theme.mixins.Black(200)}`,
    '& .search-icon': {
      '& svg': {
        '& path': {
          fill: theme.palette.secondary.contrastText,
        },
      },
    },
    '& .mega-navigation-list a:not(.active) span:not(:hover)': {
      color: theme.palette.secondary.contrastText,
    },
    '& .MuiGrid-root': {
      background: 'transparent',
    },
    '&.hidden': {
      transform: 'translateY(-14rem) !important',
      opacity: 0,
    },
    // This is done to make header visible in case of search overlay opens
    '&.fixed-header': {
      transform: 'translateY(0) !important',
      visibility: 'visible !important',
      position: 'fixed',
    },
    minHeight: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      minHeight: theme.spacing(16),
      boxShadow: `0px 2px 4px 0px ${theme.mixins.Black(100)}`,
      zIndex: '1305',
    },
  },
  megaNavOpened: {
    zIndex: '1310',
    position: 'fixed',
    transform: 'translateY(0) !important',
    transition: 'none !important',
    visibility: 'visible !important',
  },
  headerEditorMode: {
    position: 'absolute',
  },
  brandLogo: {
    display: 'block',
    maxWidth: theme.spacing(20),
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(6),
    },
    '& img': {
      maxWidth: pxToRem(122),
      height: theme.spacing(6),
      [theme.breakpoints.up('lg')]: {
        maxWidth: '100%',
        height: theme.spacing(9),
        width: 'auto',
      },
    },
  },
  navigationListWrap: {
    ...theme.mixins.paddingLeft(theme.spacing(3)),
    ...theme.mixins.paddingRight(theme.spacing(3)),
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
      paddingBottom: 0,
      '& li:last-child': {
        borderBottom: '0',
      },
    },
  },
  linkSection: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  rightSectionWrap: {
    display: 'flex',
    alignItems: 'center',
    // Decrease size to make room for additional menu items.
    '@media  (min-width: 1600px)': {
      '& .search-icon': {
        padding: ` ${pxToRem(14)}`,
      },
      '& .favorite-section-wrapper > *:first-child > *:first-child': {
        padding: pxToRem(10),
      },
    },

    '@media (min-width: 1024px) and (max-width: 1220px)': {
      '& .search-icon': {
        padding: '6px',
        '& .MuiIconButton-label': {
          position: 'relative',
          top: '-1px',
          left: '-1px',
        },
        '& svg': {
          width: '0.7em',
          height: '0.7em',
        },
      },

      '& .favorite-section-wrapper > *:first-child > *:first-child': {
        padding: '5px',
        '& svg': {
          width: '0.9em',
          height: '0.9em',
        },
      },

      '& .language-selector-wrapper > *': {
        paddingTop: '6px',
        paddingBottom: '6px',
      },
    },
  },
  menuOpened: {
    '&.MuiButtonBase-root': {
      ...theme.mixins.borderLeft(`1px solid ${theme.mixins.Black(100)}`),
      ...theme.mixins.marginLeft('-1px'),
    },
  },
  smallText: {
    color: 'inherit',
    display: 'block',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: '100%',

    [theme.breakpoints.up('lg')]: {
      ...theme.mixins.paddingLR(pxToRem(30), pxToRem(30)),
      ...theme.mixins.marginRight(theme.spacing(5)),
      ...theme.mixins.borderRight(`1px solid ${theme.mixins.Black(200)}`),
      ...theme.mixins.borderLeft(`1px solid ${theme.mixins.Black(200)}`),
      paddingTop: 0,
      paddingBottom: 0,
      width: 'auto',
      position: 'relative',
    },
    '&::after': {
      [theme.breakpoints.up('lg')]: {
        display: 'block',
        content: '""',
        width: `calc(100% - ${pxToRem(60)})`,
        borderBottom: `4px solid ${theme.mixins.AbuDhabiOrange()}`,
        position: 'absolute',
        transform: 'scaleX(0)',
        transition: 'transform 0.3s ease-in-out',
        top: theme.spacing(9),
      },
    },
    '&:hover': {
      color: theme.mixins.AbuDhabiOrange(),
      '&::after': {
        [theme.breakpoints.up('lg')]: {
          transform: 'scaleX(1)',
        },
      },
    },
  },
  rightSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('xl')]: {
      justifyContent: 'flex-end',
    },
  },
  ctaBtn: {
    width: pxToRem(80),
    borderLeft: '1px solid transparent',
    borderRadius: borderRadius.b0,
    padding: pxToRem(28),
    cursor: 'pointer',
    ...theme.mixins.marginLeft(0),
    [theme.breakpoints.up('lg')]: {
      width: 'auto',
      padding: pxToRem(12),
    },
    '& .MuiIconButton-label': {
      [theme.breakpoints.down('md')]: {
        height: pxToRem(28),
      },
    },
  },
  menuDrawer: {
    width: '100%',
    top: pxToRem(81),
    height: `calc((var(--vh, 1vh) * 100) - ${pxToRem(81)})`,
    transform: 'none !important',
    '&.disableScroll': {
      overflowY: 'hidden',
    },
  },
  searchIcon: {
    color: 'inherit',
    ...theme.mixins.marginRight(pxToRem(16)),
    [theme.breakpoints.up('lg')]: {
      transition: 'background-color 0.3s ease-in-out',
      border: '2px solid',
      borderRadius: borderRadius.circle,
    },
    '@media (min-width: 1024px) and (max-width: 1599px)': {
      padding: ` ${pxToRem(12)}`,
    },
    '&:hover': {
      [theme.breakpoints.up('lg')]: {
        backgroundColor: theme.mixins.Black(200),
      },
    },
  },
  searchRightMargin: {
    ...theme.mixins.marginLR(0, theme.spacing(7.2)),
  },
  redSearchIcon: {
    color: theme.mixins.AbuDhabiOrange(1000),
    '& svg': {
      '& path': {
        fill: `${theme.mixins.AbuDhabiOrange(1000)} !important`,
      },
    },
  },
  favoriteBadge: {
    color: 'inherit',
    '& .MuiBadge-badge': {
      right: 28,
    },
  },
  favoriteIcon: {
    color: 'inherit',
    [theme.breakpoints.up('lg')]: {
      transition: 'background-color 0.3s ease-in-out',
      border: '2px solid',
      borderRadius: borderRadius.circle,
      ...theme.mixins.marginRight(theme.spacing(3)),
    },
    '&:hover': {
      [theme.breakpoints.up('lg')]: {
        backgroundColor: theme.mixins.Black(200),
      },
    },
  },
  favoriteRightMargin: {
    ...theme.mixins.marginLR(0, theme.spacing(7.2)),
  },
  redFavoriteIcon: {
    color: theme.mixins.AbuDhabiOrange(1000),
  },

  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  },
  sectionMobile: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'stretch',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  langSelectorText: {
    color: theme.mixins.Black(700),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    '& svg:last-child': {
      fontSize: fontSize.s16Rem,
      transform: theme.mixins.rotate0(),
    },
    '& .languageLabel': {
      display: 'flex',
      '& svg': {
        ...theme.mixins.marginRight(theme.spacing(1)),
        transform: 'rotate(0deg)',
      },
    },
  },
  favSelectorText: {
    color: theme.mixins.Black(700),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    cursor: 'pointer',
    '& svg:last-child': {
      fontSize: fontSize.s22Rem,
      transform: theme.mixins.rotate0(),
    },
    '& .favrLabel': {
      display: 'flex',
      '& svg': {
        ...theme.mixins.marginRight(theme.spacing(1)),
        transform: 'rotate(0deg)',
        width: '20',
        height: '18',
      },
    },
  },

  flyout: {
    height: `calc((var(--vh, 1vh) * 100) - ${pxToRem(81)})`,
    left: '-100%',
    position: 'fixed',
    top: pxToRem(81),
    transition: 'all .3s ease',
    visibility: 'hidden',
    width: '100%',
    zIndex: '1002',
    overflowY: 'auto',
    backgroundColor: theme.mixins.White(),
    padding: `${theme.spacing(4)} 0`,
    '&.onScreen': {
      left: '0',
      visibility: 'visible',
    },
    '& li': {
      padding: 0,
      minHeight: 0,
      '& a.flyoutLink': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: `${pxToRem(20)} ${theme.spacing(5)}`,
        '&.selected': {
          backgroundColor: theme.mixins.Black(30),
        },
      },
    },

    '& .backBtn': {
      marginBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      textAlign: theme.mixins.textLeft(),
      '& .MuiButton-textSecondary': {
        fontFamily: theme.custom.fontFamily.primaryRegular,
        textTransform: 'uppercase',
      },
      '& .MuiButton-textSecondary:hover': {
        backgroundColor: 'transparent',
        color: 'inherit',
      },

      '& .MuiButton-label': {
        flexDirection: theme.mixins.flexDirection(),
      },
      '& .MuiButton-startIcon': {
        transform: theme.mixins.rotate180(),
        ...theme.mixins.marginLeft(0),
        padding: '0',
        width: 'auto',
      },
      '& > span:hover': {
        '& .MuiButton-label': {
          color: 'inherit',
        },
        '& .MuiButton-startIcon': {
          color: 'inherit',
          backgroundColor: 'transparent',
        },
      },
    },
  },
  '@global': {
    '.header-banner': {
      '&.hidden': {
        display: 'none',
      },
    },

    '.no-flip, a[href^="tel:"]': {
      unicodeBidi: 'bidi-override',
      direction: 'ltr',
      textAlign: theme.mixins.textLeft(),
    },
    '.is-ios-device .video-picture-tag': {
      overflow: 'hidden',
    },
    '.is-ios-device .video-picture-tag .pictureWithPoster': {
      position: 'relative',
    },
    '.is-ios-device .video-picture-tag .plyr': {
      position: 'absolute',
      top: 0,
      zIndex: '-1',
      opacity: 0,
      width: '100%',
    },
    '.is-ios-device .video-picture-tag .plyr.plyr--playing': {
      zIndex: '11',
      opacity: 1,
    },
    '.is-ios-device': {
      '& .plyr__control--overlaid--ios': {
        transition: 'none',
        '&:before': {
          content: '""',
          border: '2px solid transparent',
          borderTop: '2px solid #fff',
          borderRadius: borderRadius.b999px,
          animation: 'spin 2s linear infinite !important',
          position: 'absolute',
          top: '-2px',
          left: '-2px',
          width: 'calc(100% + 4px)',
          height: 'calc(100% + 4px)',
          opacity: 0,
        },
        '&.video-played': {
          borderColor: 'transparent !important',
          '&:before': {
            opacity: 1,
          },
          '& svg': {
            opacity: 0,
          },
        },
      },
    },
    '@keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' },
    },
    '.plyr__control--overlaid.plyr__control--overlaid--ios:not(.no-positioning)': {
      width: theme.spacing(6),
      height: theme.spacing(6),
      top: '50%',
      left: '50%',
      bottom: pxToRem(70),
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      background: 'transparent',
      border: `${pxToRem(2)} solid #fff`,
      zIndex: '10',
      transition: 'all 750ms cubic-bezier(0.94, 0.02, 0.59, 0.97), border 0s',
      padding: '0',

      '@media (max-width: 850px) and (orientation: landscape)': {
        top: theme.spacing(8),
        ...theme.mixins.left('auto'),
        ...theme.mixins.right(theme.spacing(-11)),
      },

      [theme.breakpoints.up('lg')]: {
        padding: pxToRem(34),
        bottom: theme.spacing(36),
        ...theme.mixins.left('auto'),
        width: theme.spacing(13.5),
        height: theme.spacing(13.5),
        top: theme.spacing(8),
        // important is requirement to override its native css
        ...theme.mixins.right(`${theme.spacing(-30.5)} !important`),
        transform: 'none',
      },

      '& svg': {
        margin: 'auto',
        ...theme.mixins.positionLR(pxToRem(1), 'auto'),
        height: pxToRem(18),
        width: pxToRem(18),
        right: '0 !important',
        [theme.breakpoints.up('lg')]: {
          height: theme.spacing(5),
          width: theme.spacing(5),
        },
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  footerFavItem: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: 72,
    boxShadow: `0px -8px 28px ${theme.palette.common.DarkBlack(100)}`,
    backgroundColor: theme.mixins.White(),
    borderRadius: borderRadius.b3px,
  },
}));
