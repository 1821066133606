import { createSelector } from 'reselect';

const getData = state => {
  return state;
};

export const searchData = createSelector(
  getData,
  data => data.SearchDataReducer
);
