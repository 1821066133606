export const COMPONENT_CONTAINER = 'header-component-container';
export const COMPONENT_SLIDER = 'header-component-slider';
export const COMPONENT_HEADER = 'header-component-header-section';
export const COMPONENT_GRID_BLOCK = 'HeaderComponent-GridBlock';
export const COMPONENT_GRID_CONTAINER = 'header-component-grid-container';

export const COMPONENT_LOGO = 'header-component-grid-item-logo';
export const COMPONENT_NAVIGATION = 'header-component-grid-item-navList';
export const COMPONENT_MOBILE_NAVIGATION =
  'header-component-grid-item-mobile-nav';
