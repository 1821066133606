import React, { useState, useCallback } from 'react';
import Typography from '../../atoms/Typography';

import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FavoriteIcon from '../FavoriteIcon';
import FavoriteList from './FavoriteList'
import clsx from 'clsx';

import useStyles from './style';
import ButtonMUI from '../../atoms/Button';
import { canUseDOM } from '../../../utils/canUseDOM';

const FavoriteSection = props => {
  const { favoriteFields, favoritesList, favoritesCount, isMobile, googlePlacesAPI, language } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hasFavorite, setHasFavorite] = React.useState(false);
  const [isFavClicked, setIsFavClicked] = React.useState(false);

  // set fav box bot bar height into css
  const favBoxBotBarRef = useCallback(node => {
    if (node !== null) {
      document.documentElement.style.setProperty('--favBoxBotBar',
        `${node.clientHeight}px`);
    }
  }, []);

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'favorite-section-popup' : undefined;

  const favoriteClickHandler = (event) => {
    handleClick(event)
    setIsFavClicked(!isFavClicked)
  };


  const handleClose = () => {
    setAnchorEl(false);
    setIsFavClicked(false)
  };

  const renderFavoriteList = () => {
    const CTA = favoriteFields.CTA;
    return (
      <div className={classes.favoriteListWrapper}>
        {favoritesCount < 1 &&
          <div className={classes.emptyListWrap}>
            {!isMobile &&
              <Typography
                variant={'h5Bold'}
                component={'h5'}
                animateSettings={{
                  willAnimate: false,
                }}
              >
                {favoriteFields?.Title.value}
              </Typography>
            }

            {isMobile &&
              <div>
                <Typography
                  variant={'h2'}
                  component={'h2'}
                  animateSettings={{
                    willAnimate: false,
                  }}
                >
                  {favoriteFields?.Title.value}
                </Typography>
              </div>
            }

            <Typography
              variant={'body2'}
              component={'span'}
              classes={{ root: classes.emptyListSpan }}
            >
              {/* Items you mark as favorite will appear here, you currently have no favorites saved to your list! */}
              {favoriteFields?.Message.value}
            </Typography>

          </div>
        }
        {favoritesCount > 0 &&
          <FavoriteList
            favoritesList={favoritesList}
            googlePlacesAPI={googlePlacesAPI}
            language={language}
            {...props}
          />
        }
        <div className={`${classes.listFooter} fav-box-bot-bar`} ref={favBoxBotBarRef}>
          <ButtonMUI
            type="button"
            buttonType="form"
            size="small"
            field={{ value: { href: CTA?.value.href } }}
            disabled={favoritesCount < 1}
            hasBorder={false}
          >
            <Typography
              variant=""
              component="span"
              color="inherit"
              className="primaryCTA"
            >
              {CTA?.value?.text} ({favoritesCount})
            </Typography>
          </ButtonMUI>
        </div>
      </div>
    )
  }

  return (
    <>
      {!isMobile &&
        <div className="favorite-section-wrapper">
          <React.Fragment>
            <div className={classes.favRoot} aria-describedby={id}>
              <FavoriteIcon
                favoritesCount={favoritesCount}
                onClickHandler={(e) => favoriteClickHandler(e)}
                iconOnly={true}
                aria-label="favorite button"
                edge="start"
                hasFavorite={hasFavorite}
                isFavClicked={isFavClicked}
                isMobile={isMobile}
                className={clsx(
                  classes.favoriteIcon,
                )}
              />
            </div>
          </React.Fragment>
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            disablePortal={true}
            placement="bottom-end"
            transition={true}
          >
            <ClickAwayListener onClickAway={handleClose}>
              {renderFavoriteList()}
            </ClickAwayListener>
          </Popper>
        </div>
      }

      {isMobile && <div>
        {renderFavoriteList()}
      </div>}
    </>
  );
};

FavoriteSection.prototype = {

};

FavoriteSection.defaultProps = {

};

export default FavoriteSection;
