// This method returns card category tags in the order of the keys provided by the dictionary
// We pick the key from dictionary and extract the tag from api card data
// Each page is having different keys and its order that comes from dictionary service dynamically generated using page contentType
export const getCardCategoryTags = (item, dictionaryTags) => {
  const tags = [];
  dictionaryTags = dictionaryTags && dictionaryTags.split(',');
  dictionaryTags &&
    dictionaryTags.length > 0 &&
    dictionaryTags.forEach(tag => {
      if (item[tag] && item[tag].length > 0) {
        item[tag][0] && tags.push(item[tag][0]);
        //tags.push(item[tag][getRandomIndex(item[tag].length)]);
      }
    });
  return tags;
};

// const getRandomIndex = arrayLength => {
//   return Math.floor(Math.random() * arrayLength);
// };
