import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { mobileMedia, pxToRem } from '../../../../styles/themes/vad/constants';

export default makeStyles(theme => ({
  favRoot: {
    ...theme.mixins.marginRight(pxToRem(16)),
    width: '52px',
    '@media (min-width: 1440px) and (max-width: 1599px)': {
      width: '44px',
    },
    '@media (min-width: 1024px) and (max-width: 1439px)': {
      width: '35.97px',
    },
  },

  favoriteListWrapper: {
    marginTop: theme.spacing(2),
    borderRadius: borderRadius.b3,
    backgroundColor: theme.mixins.White(),
    boxShadow: `0px 4px 30px ${theme.mixins.Black(180)}`, // '0px 4px 30px rgba(0, 0, 0, 0.18)'
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    maxWidth: '375px',
    overflowX: 'hidden',
    margin: 'auto',
    minHeight: '200px',
    maxHeight: 'calc((var(--vh, 1vh) * 100) - var(--notificationBar) - var(--headerHeight) - var(--transparentHeaderHeight))',
    boxSizing: 'content-box',
    scrollBehavior: 'smooth',
    overflowY: 'auto',

    '.MuiAppBar-positionFixed &': {
      maxHeight: 'calc((var(--vh, 1vh) * 100) - var(--headerHeight) - var(--transparentHeaderHeight))',
    },
    color: theme.mixins.Black(1000),
    [mobileMedia]: {
      marginTop: 0,
      boxShadow: 'none',
      flexDirection: 'column',
      maxHeight: 'none',
      maxWidth: '100%',
      paddingBottom: 'calc(var(--favBoxBotBar) - 2em)',
    },
    '& .MuiList-padding': {
      padding: 0,
      width: pxToRem(300),
    },
    '& li': {
      padding: pxToRem(16),
      '&.selected': {
        backgroundColor: theme.mixins.Black(30),
        position: 'relative',
      },
      '&:hover': {
        backgroundColor: theme.mixins.Black(30),
      },
    },
  },
  title: {
    textAlign: theme.mixins.textLeft(),
    ...theme.mixins.marginLeft(pxToRem(28)),
  },
  emptyListWrap: {
    paddingTop: `${pxToRem(32)}`,
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    textAlign: 'center',
    width: '375px',
    height: '184px',
    [mobileMedia]: {
      paddingTop: 0,
      width: '100%',
      height: '100%',
      textAlign: theme.mixins.textLeft(),
      '& h2': {
        ...theme.mixins.paddingLeft(pxToRem(40)),
      },
    },
  },
  emptyListSpan: {
    color: theme.palette.common.UnselectedColor,
    paddingTop: pxToRem(12),
    paddingLeft: pxToRem(48),
    paddingRight: pxToRem(48),
    [mobileMedia]: {
      width: '65%',
      minWidth: '251px',
      ...theme.mixins.paddingLeft(pxToRem(32)),
      ...theme.mixins.paddingRight(pxToRem(0)),
    },
  },
  favList: {
    position: 'relative',
    width: '375px',
    height: '100%',
    paddingTop: pxToRem(8),
    listStyleType: 'none',
    marginBlockStart: 0,
    marginBlockEnd: 0,
    paddingInlineStart: 0,
    [mobileMedia]: {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
    },

    '& li': {
      padding: pxToRem(16),
      textAlign: theme.mixins.textLeft(),
    },
    '& li:last-child': {
      borderBottom: '0px',
    },
  },
  listItem: {
    position: 'relative',
  },
  listFooter: {
    position: 'relative',
    width: '375px',
    height: '72px',
    zIndex: 10,
    borderTop: `1px solid ${theme.palette.common.DisabledGrey}`,
    backgroundColor: theme.mixins.White(),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [mobileMedia]: {
      width: '100%',
      position: 'fixed',
      bottom: '0px',
    },
  },
  '@global': {
    '#favorite-section-popup': {
      zIndex: 1400,
    },
  },
}));
