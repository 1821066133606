import * as actionTypes from './actionTypes';

/**
 * actions for fetching filters data
 */
export const updateSearchData = payload => {
  return {
    type: actionTypes.SET_SEARCH_DATA,
    payload,
  };
};
