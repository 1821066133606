import React from 'react';
import PropType from 'prop-types';
import Typography from '../../atoms/Typography';

import FavoriteIcon from '../FavoriteIcon';
import clsx from 'clsx';

import useStyles from './style';
import ButtonMUI from '../../atoms/Button';
import FavoriteItem from '../../atoms/FavoriteItem';
import { getDictionaryText } from '../../../utils/getDictionaryText';

const FavoriteList = props => {
  const { favoritesList, isMobile, ...rest } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      {isMobile &&
        <>
          <Typography variant={'h2'} classes={{ root: classes.title }} component={'h2'}>
            {getDictionaryText('favorites')}
          </Typography>
        </>
      }
      <ul className={classes.favList}>
        {favoritesList.map(item => {
          return (
            <FavoriteItem key={item.itemUniqueKey} item={item} {...rest} />
          )
        })}
      </ul>
    </React.Fragment>
  );
};

FavoriteList.prototype = {

};

FavoriteList.defaultProps = {

};

export default FavoriteList;
